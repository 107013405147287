import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { toQueryParams } from "../utils/http.util";
import { PublicConfigService } from "./public.config.service";

@Injectable({
  providedIn: 'root'
})
export class EvaluationJudgingService {

  constructor(private httpClient: HttpClient, private publicConfigService: PublicConfigService) {

  }

  GetEvaluationJudgingList(stageId: string) {
    return this.httpClient.get(`${this.publicConfigService.rpBaseUrl}/api/judging/${stageId}/judge-judgements-list`);
  }

  getCOIList(stageId: string) {
    return this.httpClient.get(`${this.publicConfigService.rpBaseUrl}/api/judging/cois-list`, {
      params: toQueryParams({ StageId: stageId })
    });
  }

  getJudgementSteps(judgementId: string) {
    return this.httpClient.get(`${this.publicConfigService.rpBaseUrl}/api/judging/${judgementId}/judgement-steps`);
  }

  saveJudgementSteps(body: any) {
    return this.httpClient.put(`${this.publicConfigService.rpBaseUrl}/api/judging/upsert-judgement-step`, body);
  }

  doCOISwap(judgementId: string) {
    return this.httpClient.post(`${this.publicConfigService.rpBaseUrl}/api/judging/swap`, { judgementId });
  }

  editReview(judgementId: string) {
    return this.httpClient.put(`${this.publicConfigService.rpBaseUrl}/api/judging/${judgementId}/unsubmit`, null);
  }

  editJudgementComment(judgementId: string, judgeComment: string) {
    return this.httpClient.put(`${this.publicConfigService.rpBaseUrl}/api/judging/judgementrubrictrait/${judgementId}`, {comment: judgeComment});
  }
}
